exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-agreement-js": () => import("./../../../src/pages/agreement.js" /* webpackChunkName: "component---src-pages-agreement-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-auth-google-js": () => import("./../../../src/pages/auth/google.js" /* webpackChunkName: "component---src-pages-auth-google-js" */),
  "component---src-pages-auth-index-js": () => import("./../../../src/pages/auth/index.js" /* webpackChunkName: "component---src-pages-auth-index-js" */),
  "component---src-pages-auth-vk-js": () => import("./../../../src/pages/auth/vk.js" /* webpackChunkName: "component---src-pages-auth-vk-js" */),
  "component---src-pages-auth-yandex-js": () => import("./../../../src/pages/auth/yandex.js" /* webpackChunkName: "component---src-pages-auth-yandex-js" */),
  "component---src-pages-entry-index-js": () => import("./../../../src/pages/entry/index.js" /* webpackChunkName: "component---src-pages-entry-index-js" */),
  "component---src-pages-entry-review-js": () => import("./../../../src/pages/entry/review.js" /* webpackChunkName: "component---src-pages-entry-review-js" */),
  "component---src-pages-entry-starter-js": () => import("./../../../src/pages/entry/starter.js" /* webpackChunkName: "component---src-pages-entry-starter-js" */),
  "component---src-pages-god-entryreview-js": () => import("./../../../src/pages/god/entryreview.js" /* webpackChunkName: "component---src-pages-god-entryreview-js" */),
  "component---src-pages-god-index-js": () => import("./../../../src/pages/god/index.js" /* webpackChunkName: "component---src-pages-god-index-js" */),
  "component---src-pages-god-promo-js": () => import("./../../../src/pages/god/promo.js" /* webpackChunkName: "component---src-pages-god-promo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-promocode-js": () => import("./../../../src/pages/promocode.js" /* webpackChunkName: "component---src-pages-promocode-js" */),
  "component---src-pages-telegram-js": () => import("./../../../src/pages/telegram.js" /* webpackChunkName: "component---src-pages-telegram-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-expert-js": () => import("./../../../src/templates/expert.js" /* webpackChunkName: "component---src-templates-expert-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

