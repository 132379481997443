import React from "react";
import Logo from "../images/svg/logo";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ContextProvider } from "../context/ContextProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
    },
  },
});

function Layout({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <ContextProvider>
        <div className="start">
          <Logo />
          <a href="https://vk.com/graphiksi">Группа ВК</a>
        </div>
        {/* <main>{children}</main> */}
      </ContextProvider>
    </QueryClientProvider>
  );
}

export default Layout;
