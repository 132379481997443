import * as React from "react";

function Logo(props) {
  return (
    <svg viewBox="0 0 256 33" {...props}>
      <path d="M28.274.633v6.413H1.887v18.186H-.027V.632h28.3zM65.066 13.187c0 6.661-4.761 12.057-10.627 12.057H42.752c-3.485 0-6.561-1.904-8.502-4.844v10.88h-2.125V1.122h2.126v4.85c1.914-2.94 5.016-4.85 8.501-4.85H54.44c5.853 0 10.627 5.402 10.627 12.064zm-2.125 0c0-2.667-1.915-4.826-4.25-4.826H38.5c-2.349 0-4.25 2.159-4.25 4.826s1.914 4.825 4.25 4.825h20.19c2.348-.012 4.25-2.164 4.25-4.825zM99.865 1.123v24.121h-2.126V20.4c-1.915 2.94-5.016 4.844-8.501 4.844H77.55c-5.866 0-10.621-5.396-10.621-12.057 0-6.662 4.755-12.064 10.62-12.064h11.688c3.485 0 6.561 1.91 8.501 4.85v-4.85h2.126zm-2.126 12.064c0-2.667-1.915-4.826-4.25-4.826h-20.19c-2.342 0-4.244 2.159-4.244 4.826s1.915 4.825 4.245 4.825h20.182c2.362-.012 4.264-2.164 4.264-4.825h-.007zM141.295 13.198c0 6.662-4.755 12.064-10.628 12.064h-7.435V32.5h-2.126v-7.238h-7.436c-5.866 0-10.627-5.402-10.627-12.064 0-6.661 4.761-12.057 10.627-12.057h16.997c5.873 0 10.628 5.396 10.628 12.057zm-20.189-4.82h-11.667c-2.343 0-4.252 2.16-4.252 4.82 0 2.661 1.915 4.826 4.252 4.826h11.686l-.019-9.645zm18.063 4.82c0-2.66-1.914-4.82-4.251-4.82h-11.686v9.62h11.686c2.349 0 4.251-2.133 4.251-4.8zM169.707 1.123v24.121H153.75c-5.285 0-9.574-4.856-9.574-10.854V1.123h2.119V14.39c0 2.003 1.43 3.622 3.191 3.622h18.064V1.123h2.157zM195.867 13.187l3.543 12.057h-2.662l-2.834-9.645h-20.01v9.645h-2.119V1.124h2.119V8.36h20.719l2.125-7.238h2.687l-3.568 12.064zM202.883 13.187c0 2.66 1.915 4.825 4.251 4.825h21.249v7.232h-16.998c-5.872 0-10.627-5.396-10.627-12.057 0-6.662 4.755-12.064 10.627-12.064h16.998v7.238h-21.249c-2.349 0-4.251 2.159-4.251 4.826zM256 1.123v24.121h-15.957c-5.279 0-9.574-4.856-9.574-10.854V1.123h2.157V14.39c0 2.003 1.43 3.622 3.191 3.622h18.064V1.123H256z" />
    </svg>
  );
}

const MemoLogo = React.memo(Logo);
export default MemoLogo;
